<template>
    <div class="logs-container">
      <div v-if="loading">
        <p class="text-ibm-plex-sans loading">
          {{ $t('Loading') }}
          <font-awesome-icon icon="spinner" class="fa-lg" spin /></p>
      </div>
      <div v-else>
        <div v-for="table of tableList" :key="table.id">
        <span class="patient-data">
          <p class="dark-text">{{ $t('adherence_technology') }}: </p>
            <b class="dark-text">{{ $t(table.adhTech) }}</b>
        </span>
        <span class="patient-data" v-if="table.phoneNumber !== null">
          <p class="dark-text">{{ $t('patient_phone_number') }}: </p>
            <b class="dark-text">{{ table.phoneNumber }}</b>
        </span>
        <Table :columns="table.tableColumns" :data="table.tableData" :loading="loading" :pageSize="table.totalLogs"></Table>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Table from '../../../../shared/components/Table.vue'

export default {
  name: 'CallLogs',
  components: {
    Table
  },
  data () {
    return {
      loading: true,
      tableList: []
    }
  },
  props: {
    patientId: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions('CallLogs', ['getPatientCallLogs']),
    async loadCallLogs () {
      this.loading = true
      this.callLogsData = await this.getPatientCallLogs(this.patientId)
      this.tableList = this.callLogsData.tableList
      this.loading = false
    }
  },
  async mounted () {
    await this.loadCallLogs()
  }
}
</script>

<style lang="scss" scoped>

.heading {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.005em;
    color: #1E234C;
    margin: 10px 0px 10px 0px;
}
.patient-data {
    display: grid;
    grid-template-columns: repeat(2, 0.15fr);
    margin-bottom: 15px;
}

.log-line {
    margin: 0px 0 10px 0;
    height: 1px;
    background-color: #cacbd9;
}

.loading {
    margin-top: 10px !important;
    text-align: center;
}

.logs-container {
  padding: 20px;
  background-color: var(--background-color-primary-light-dark);
}
</style>
